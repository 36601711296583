import React from "react";
import TopLevel from "../assets/toplevel.svg";
import { RxDragHandleDots1 } from "react-icons/rx";
import { BiLoaderCircle } from "react-icons/bi";

const Toplevel = () => {
  return (
    <div className="top-level-main">
      <div className="top-level">
        <BiLoaderCircle
          className="toplevel-designs toplevel-designs-small"
          style={{ top: "80px", left: "83%" }}
        />
        <RxDragHandleDots1
          className="mobile-invisible toplevel-designs"
          style={{ top: "100px", left: "40%" }}
        />
        <RxDragHandleDots1
          className="mobile-invisible toplevel-designs"
          style={{ top: "320px", left: "10px" }}
        />
        <div className="toplevel-content">
          <h2>
            Become an Expert <br></br>in Technology Development<br></br>with
            ease
          </h2>
          <p>
            Kayrex offers wide variety of both technical and non technical
            courses <br></br>that can help you transition to Tech without coding
          </p>
          <a href="https://wa.me/+353899892887?text=Hello, Kayrex Academy. I will like to enquire about your learning curriculum"  target="_blank" rel="noreferrer" className="toplevel-button">Explore Curriculum</a>
        </div>
        <img alt="Kayrex Academy" src={TopLevel} className="top-level-image" />
      </div>
      <div>
        <svg height="100" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" className="toplevel-triangle">
          <path d="M0 0 L50 50 L100 0 Z"/>
        </svg>
      </div>
    </div>
  );
};

export default Toplevel;
