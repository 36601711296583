import React from 'react';
import { TbLiveView } from 'react-icons/tb';

const reasons = [
    {
      icon: <TbLiveView style={{fontSize:'30px',color:"#6e363a"}}></TbLiveView>,
      title: 'Opportunity to work on live project',
      description: 'Availability in areas of Response Time, Call Back Time, and Services Delivery.'
    },
    {
      icon: <TbLiveView style={{fontSize:'30px',color:"#6e363a"}}></TbLiveView>,
      title: 'Advance Interview Preparations',
      description: 'We offer the most competitive rates in the industry without compromising on service quality.'
    },
    {
      icon: <TbLiveView style={{fontSize:'30px',color:"#6e363a"}}></TbLiveView>,
      title: 'Free up to date standard CV generation',
      description: 'Our tickets come with flexible options, allowing you to make changes as per your convenience.'
    },
    {
      icon: <TbLiveView style={{fontSize:'30px',color:"#6e363a"}}></TbLiveView>,
      title: 'Referencing provided',
      description: 'Enjoy significant discounts on fares, making your travel more affordable.'
    },
    {
      icon: <TbLiveView style={{fontSize:'30px',color:"#6e363a"}}></TbLiveView>,
      title: 'Community support when you land a job',
      description: 'Take advantage of our regular promotional deals to save more on your travels.'
    },
    {
      icon: <TbLiveView style={{fontSize:'30px',color:"#6e363a"}}></TbLiveView>,
      title: 'Extra classes outside regular curriculum',
      description: 'Our team maintains the highest level of professionalism in all interactions.'
    },
    {
      icon: <TbLiveView style={{fontSize:'30px',color:"#6e363a"}}></TbLiveView>,
      title: 'Lifetime subscription to Kayrex Academy',
      description: 'Experience a hassle-free refund process with our enhanced procedures.'
    }
  ];
const Benefit = () => {
    return (
      <div className="featured-main">
        <div className="simple-flex">
          <div className="identifier"></div>
          <h4 className="bolder">LEARNING WITH KAYREX</h4>
        </div>
        <div className="reason-grid">
          {reasons.map((reason, index) => (
            <div className="reason-box" key={index}>
                {reason.icon}
              <p className='reason-header'>{reason.title}</p>
              {/* <p className='reason-content'>{reason.description}</p> */}
            </div>
          ))}
        </div>
      </div>
    );
}

export default Benefit;
