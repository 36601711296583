import React, { useState } from "react";
import Logo from "../assets/kayrex.jpg";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTiktok,
  BsTwitter,
} from "react-icons/bs";
import { MdEmail, MdSupportAgent } from "react-icons/md";
import { TbSocial } from "react-icons/tb";

const Header = () => {
  const [showSocial, showSocialChanged] = useState(false);
  return (
    <div className="header-main">
      <img alt="Kayrex" src={Logo} className="header-logo" />
      <div className="d-flex align-items-center gap-3">
        <div className="header-icons mobile-visible">
          <div onClick={() => showSocialChanged(!showSocial)}>
            <TbSocial />
          </div>
          {showSocial ? (
            <div className="header-overlay">
              <a
                href="https://www.facebook.com/profile.php?id=100094205407984
"
                target="_blank"
                rel="noreferrer"
              >
                <BsFacebook className="header-icons" />
              </a>
              <br></br>

              <a
                href="https://instagram.com/kayrexacademy?igshid=MzRlODBiNWFlZA==
"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="header-icons" />
              </a>
              <br></br>

              <a
                href="https://www.tiktok.com/@kayrexacademy?_t=8oSvXbY6e1o&_r=1"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <BsTiktok className="header-icons" />
              </a>
              <br></br>

              <a
                href="https://x.com/kayrexacademy?s=11&t=6ie5kieNQlXKyB3DP1-wLg"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter className="header-icons" />
              </a>
              <br></br>

              <a
                href="mailto:info@kayrexacademy.com"
                target="_blank"
                rel="noreferrer"
              >
                <MdEmail className="header-icons" />
              </a>
              <br></br>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <BsFacebook
          className="header-icons mobile-invisible"
          onClick={() =>
            window.open(
             "https://web.facebook.com/people/Kayrex/100094205407984/"
            )
          }
        />
        <BsInstagram
          className="header-icons mobile-invisible"
          onClick={() =>
            window.open(
              "https://instagram.com/kayrexacademy?igshid=MzRlODBiNWFlZA=="
            )
          }
        />
        <BsTiktok
          className="header-icons mobile-invisible"
          onClick={() =>
            window.open(
              "https://www.tiktok.com/@kayrexacademy?_t=8oSvXbY6e1o&_r=1"
            )
          }
        />
        <BsTwitter
          className="header-icons mobile-invisible"
          onClick={() =>
            window.open(
              "https://x.com/kayrexacademy?s=11&t=6ie5kieNQlXKyB3DP1-wLg"
            )
          }
        />
        <a
          href="mailto:info@kayrexacademy.com"
          target="_blank"
          rel="noreferrer"
        >
          <MdEmail className="header-icons mobile-invisible" />
        </a>
        <a
          className="header-button"
          href="https://wa.me/+353899892887?text=Hello, Kayrex Academy. I will like to enquire about your bootcamps"
          target="_blank"
          rel="noreferrer"
        >
          <MdSupportAgent></MdSupportAgent> Live Chat
        </a>
      </div>
    </div>
  );
};

export default Header;
