import axios from "axios";
const url =
  "https://script.google.com/macros/s/AKfycbyW4I4P_gceX69DLQIsBWom330JxnUG3OkoMPGyP_70QDfcdMZ6qWp3MZeFvhE7YlHhxQ/exec";

export async function NewVisitor() {
  const visited = localStorage.visited;
  if (visited !== "visited") {
    await axios.get(`${url}?path=kayrexconnect`);
    localStorage.visited = "visited";
  }
}
export async function NewsLetter(email) {
  try {
    await axios.get(`https://script.google.com/macros/s/AKfycbyW4I4P_gceX69DLQIsBWom330JxnUG3OkoMPGyP_70QDfcdMZ6qWp3MZeFvhE7YlHhxQ/exec?path=kayrexconnectletter&email=${email}`)
  } catch (error) {
    console.log(error)
  }
}
export async function NewFormRegistration(data) {
  await axios.post(`${url}?path=kayrexconnect`, JSON.stringify(data));
}
