import React from "react";
import WhyUsImage from "../assets/why.jpg";
import { BsPeopleFill } from "react-icons/bs";
import { FaMoneyBill } from "react-icons/fa";
import { MdHighQuality } from "react-icons/md";

const Whyus = () => {
  return (
    <div className="page-margin">
      <div className="simple-flex">
        <div className="identifier"></div>
        <h4 className="bolder">WHY CHOOSE US</h4>
      </div>
      <div className="whyus-flex">
        <div>
          <img alt="kayrex" src={WhyUsImage} className="whyus" />
        </div>
        <div className="vertical-flex">
            <br></br>
            <br></br>
          <div className="simple-flex gap-3">
            <div className="rect-one"><BsPeopleFill></BsPeopleFill></div>
            <div>
              <h4>Professional Trainers</h4>
              <p>we boast to have some of the best trainers to help you achieve your learning goals</p>
            </div>
          </div>
          <div className="simple-flex gap-3">
            <div className="rect-one"><FaMoneyBill></FaMoneyBill></div>
            <div>
              <h4>Affordable Prices</h4>
              <p>Get access to our technology boot camp with as little as £200 for a complete course</p>
            </div>
          </div>
          <div className="simple-flex gap-3">
            <div className="rect-one"><MdHighQuality></MdHighQuality></div>
            <div>
              <h4>Tailor-Made Learning Plan</h4>
              <p>We offer taylor made programs for all our students to ensure they get best of our training</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whyus;
