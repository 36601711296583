import React from "react";

const Services = () => {
  return (
    <div className="featured-main">
      <div className="simple-flex">
        <div className="identifier"></div>
        <h4 className="bolder">FEATURED COURSES</h4>
      </div>
      <div className="simple-flex-courses">
        <div className="courses-box">
          <h5 className="course-header bolder">Business Analysis</h5>
          <p className="course-content sc">1 month course | 20 classes</p>
        </div>
        <div className="courses-box">
          <h5 className="course-header bolder">Product Management</h5>
          <p className="course-content sc">1 month course | 15 classes</p>
        </div>
        <div className="courses-box">
          <h5 className="course-header bolder">Data Analysis</h5>
          <p className="course-content sc">2 month course | 30 classes</p>
        </div>
        <div className="courses-box">
          <h5 className="course-header bolder">UI/UX Designer</h5>
          <p className="course-content sc">1 month course | 20 classes</p>
        </div>
        <div className="courses-box">
          <h5 className="course-header bolder">Scrum Certification</h5>
          <p className="course-content sc">1 month course | 13 classes</p>
        </div>
        <div className="courses-box">
          <h5 className="course-header bolder">Cyber Security</h5>
          <p className="course-content sc">2 month course | 20 classes</p>
        </div>
        <div className="courses-box">
          <h5 className="course-header bolder">Software Development</h5>
          <p className="course-content sc">3 month course | 45 classes</p>
        </div>
        <div className="courses-box">
          <h5 className="course-header bolder">Devops Engineering</h5>
          <p className="course-content sc">2 month course | 25 classes</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
