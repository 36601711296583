import React, { useState } from "react";
import Logo from "../assets/kayrex.jpg";
import { MdEmail } from "react-icons/md";
import { BsFacebook, BsInstagram, BsLinkedin, BsPhoneFill, BsSendArrowUp, BsTiktok, BsTwitter } from "react-icons/bs";
import { NewsLetter } from "../services/server";

const Footer = () => {
  const [email, emailUpdated]=useState("")
  const RegisterLetter=async ()=>{
    await NewsLetter(email)
    emailUpdated("")
    alert("Thank you for signing up for Kayrex NewsLetter")
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  }
  return (
    <div className="footermain">
      <div className="footertop">
        <div>
          <img alt="logo" src={Logo} className="footerlogo"/>
          <p className="compdetail">
            Kayrex offers IT Online/Virtual Training ,live projects and Business
            related courses throughout the United Kingdom, Ireland, EU, America,
            Canada and Africa. With a solid history of performance Our short
            courses, taught by expert professionals in relevant IT and
            programming fields,(Business Analysis , Data Analysis and
            cybersecurity, Software Development)will help you gain the most
            in-demand skills.
          </p>
        </div>
        <div className="footerbootcamp">
            <p>Get updated on our Bootcamps</p>
            <div className="d-flex w-100">
                <input type="email" value={email} onChange={(chg)=>emailUpdated(chg.target.value)} placeholder="Enter your email"/><button className="sendbut" onClick={()=>RegisterLetter()} ><BsSendArrowUp></BsSendArrowUp></button>
            </div>   
        </div>
        <div>
            <h3>Get In Touch</h3>
            <p><MdEmail></MdEmail> <a href='mailto:info@kayrexacademy.com' className="footer-phone">info@kayrexacademy.com</a></p>
            <p><BsPhoneFill></BsPhoneFill> <a className="footer-phone" href="tel:+353899892887">+353 89 989 2887</a> | <a className="footer-phone" href="tel:+447300648774">+44 7300 648774</a>  </p>
            <div>
              <a
                href="https://www.facebook.com/profile.php?id=100094205407984
"
                target="_blank"
                rel="noreferrer"
              >
                <BsFacebook className="header-icons txt-white mx-2" />
              </a>

              <a
                href="https://instagram.com/kayrexacademy?igshid=MzRlODBiNWFlZA==
"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="header-icons txt-white mx-2" />
              </a>
              <a
                href="https://www.tiktok.com/@kayrexacademy?_t=8oSvXbY6e1o&_r=1"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <BsTiktok className="header-icons txt-white mx-2" />
              </a>
              
              <a
                href="https://x.com/kayrexacademy?s=11&t=6ie5kieNQlXKyB3DP1-wLg"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter className="header-icons txt-white mx-2" />
              </a>
            </div>
        </div>
      </div>
      <hr className="hrline"></hr>
      <p style={{textAlign:'center'}}>Copyright © 2024 Kayrex Academy LLC</p>
    </div>
  );
};

export default Footer;
