import React, { useEffect } from "react";
import Header from "../components/header";
import Toplevel from "../components/toplevel";
import Whyus from "../components/whyus";
import Services from "../components/services";
import Contactus from "../components/contactus";
import Footer from "../components/footer";
import { NewVisitor } from "../services/server";
import Benefit from "../components/benefit";
import Programs from "../components/programs";

const Home = () => {
    useEffect(()=>{
        const newVisitor=async()=>{
            await NewVisitor()
        }
        newVisitor()
    },[])
  return (
    <div>
      <Header></Header>
      <Toplevel></Toplevel>
      <Whyus></Whyus>
      <Benefit></Benefit>
      <Programs></Programs>
      <Services></Services>
      <Contactus></Contactus>
      <Footer></Footer>
    </div>
  );
};

export default Home;
